import type { App } from "vue"
import { useSessionStore } from "../stores/session"

export default function(app: App) {
  const router = app.config.globalProperties.$router
  const pinia = app.config.globalProperties.$pinia

  router.beforeResolve((to, from, next) => {
    const session = useSessionStore()

    if (to.meta.auth != false && !session.isSignIn) {
      next({ name: "login", query: { ok_url: to.fullPath }})
    } else {
      next()
    }
  })
}
