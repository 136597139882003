import { ref, computed, type App, watch } from 'vue'
import { defineStore, getActivePinia } from 'pinia'
import type { Account } from '@/frontend/models'
import { useCookieState } from 'vue-hooks-plus'
import { usePageStore } from './page'
import * as requests from '@/frontend/requests'

export const useSessionStore = defineStore('session', () => {
  const app = (getActivePinia() as any)._a as App
  const proxy = app.config.globalProperties

  const localAccount = ref(undefined as Account | null | undefined)
  watch(localAccount, (newValue) => {
    if (newValue === undefined) {
      return
    }

    setRawSignIn(newValue !== null ? "true" : "false")
  })

  const [ isRawSignIn, setRawSignIn ] = useCookieState("sign_in")
  const isSignIn = computed(() => isRawSignIn.value === "true")

  async function account() {
    const page = usePageStore()

    if (localAccount.value !== undefined) {
      return localAccount.value
    }

    const result = await page.global(requests.session.Get).fire()
    localAccount.value = result
    return result
  }

  function setAccount(account: Account | null) {
    localAccount.value = account
  }

  return { isSignIn, account, setAccount }
})
