import * as t from '@/frontend/lib/transforms'
import _ from 'lodash'

export class Synchronization {
  @t.Number id!: number

  state!: 'pending' | 'running' | 'success' | 'failure'
  failureReason!: string | null
  lastSyncedAt!: Date

  step!: string
  @t.Raw stepParams!: Record<string, any>

  get failureMessage() { return _.first(this.failureReason?.split("\n")) }
  get failureBacktraces() { return _.slice(this.failureReason?.split("\n"), 1) }
}
