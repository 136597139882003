import '@/vendor/fontawesome'
import '@/vendor/shadcn'

import './assets/main.css'

import { createApp, type App } from 'vue'
import Root from './Root.vue'

const app = createApp(Root)

import * as initializers from './initializers'
for (const initializer of Object.values(initializers)) {
  initializer(app)
}

app.mount('#app')
