export const routes = [
  {
    path: '/',
    name: 'root',
    component: () => import('/private/var/folders/zz/b1gy35ws0qb6gnrhk9xbk2hw0000gp/T/capsum/Users/bevis/Workspaces/projects/jack-jack/0xWatchman/app/javascript/frontend/pages/index.vue'),
    /* no children */
    meta: {
      "auth": false
    }
  },
  {
    path: '/app',
    /* internal name: '/app' */
    /* no component */
    children: [
      {
        path: '',
        name: 'app',
        component: () => import('/private/var/folders/zz/b1gy35ws0qb6gnrhk9xbk2hw0000gp/T/capsum/Users/bevis/Workspaces/projects/jack-jack/0xWatchman/app/javascript/frontend/pages/app/index.vue'),
        /* no children */
      },
      {
        path: 'dashboard',
        name: '/app/dashboard',
        component: () => import('/private/var/folders/zz/b1gy35ws0qb6gnrhk9xbk2hw0000gp/T/capsum/Users/bevis/Workspaces/projects/jack-jack/0xWatchman/app/javascript/frontend/pages/app/dashboard.vue'),
        /* no children */
        meta: {
          "layout": "app"
        }
      },
      {
        path: 'subjects',
        name: '/app/subjects',
        component: () => import('/private/var/folders/zz/b1gy35ws0qb6gnrhk9xbk2hw0000gp/T/capsum/Users/bevis/Workspaces/projects/jack-jack/0xWatchman/app/javascript/frontend/pages/app/subjects.vue'),
        /* no children */
        meta: {
          "layout": "app"
        }
      }
    ],
  },
  {
    path: '/home',
    name: '/home',
    component: () => import('/private/var/folders/zz/b1gy35ws0qb6gnrhk9xbk2hw0000gp/T/capsum/Users/bevis/Workspaces/projects/jack-jack/0xWatchman/app/javascript/frontend/pages/home.vue'),
    /* no children */
    meta: {
      "auth": false
    }
  },
  {
    path: '/sign_in',
    name: 'login',
    component: () => import('/private/var/folders/zz/b1gy35ws0qb6gnrhk9xbk2hw0000gp/T/capsum/Users/bevis/Workspaces/projects/jack-jack/0xWatchman/app/javascript/frontend/pages/sign_in.vue'),
    /* no children */
    meta: {
      "auth": false
    }
  }
]
