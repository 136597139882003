import type { AxiosResponse } from "axios"
import BaseRequest from "./BaseRequest"
import { Account } from "@/frontend/models"

export default {
  Create: class extends BaseRequest<Account> {
    endpoint = '/session'
    method = "POST"

    processResponse(response: AxiosResponse) {
      return this.responseToObject(Account, response)
    }
  },

  Destroy: class extends BaseRequest<void> {
    endpoint = '/session'
    method = "DELETE"

    processResponse(response: AxiosResponse): void { }
  },

  Get: class extends BaseRequest<Account | null> {
    endpoint = '/session'
    method = "GET"

    processResponse(response: AxiosResponse) {
      if (response.status == 404) {
        return null
      } else {
        return this.responseToObject(Account, response)
      }
    }

    validateStatus(status: number): boolean {
      return super.validateStatus(status) || (status === 404)
    }
  }
}
