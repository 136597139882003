import type BaseRequest from '@/frontend/requests/BaseRequest'
import { defineStore, getActivePinia } from 'pinia'
import { reactive, ref, type App } from 'vue'

export const usePageStore = defineStore('page', () => {
  const app = (getActivePinia() as any)._a as App
  const globals = reactive(new Map<any, BaseRequest<any>>())

  function global<T extends BaseRequest<any>>(klass: new (...args: any[]) => T): T {
    if (globals.has(klass)) {
      return globals.get(klass) as T
    }

    const instance = new klass(app.config.globalProperties)
    globals.set(klass, instance)
    return instance
  }

  function clearFor(klass: new () => BaseRequest<any>) {
    globals.delete(klass)
  }

  function clear() {
    globals.clear()
  }

  return { global, clear, clearFor }
})

